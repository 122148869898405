import React, { useEffect , useState } from "react";
import { useForm } from "react-hook-form";
import * as Constants from '../../config/constants';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { seo } from "../../seo";
import {  apiCall , manageNetworkError ,  emailRegex, decode, dynamicMetaTags, noSpace } from "../../assets/js/common";
import { useTranslation } from "react-i18next";
import {
	Container,
	Row,
	Col,
	Form,
    FormGroup,
} from "react-bootstrap";
import { HelmetProvider } from 'react-helmet-async';
import InputTextField from "../../component/InputTextField";
import PasswordField from "../../component/PasswordField";
import { Checkbox } from "primereact/checkbox";
import { useCookies } from 'react-cookie';
import logo from "../../assets/images/logo.png";
import FlashMessage from "../../component/FlashMessage";
import LoginHeader from "./guest/login-header";

export default function Login() {

    const { t } = useTranslation( [ "translation", "errorMessage" ]);
    const [cookies , setCookie , removeCookie] = useCookies(Constants.LOGIN_COOKIE_NAME);
    const navigate = useNavigate();
    const [isloggedIn , setIsLoggedIn] = useState(false);
    const [settingInfo, setSettingInfo] = useState([]);
    const [isRememberMeChecked , setIsRememberMeChecked] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [flashMessage , setFlashMessage] = useState(null);
    const { state } = useLocation();
    useEffect(() => {
        if(state) {
            setFlashMessage(state);
            window.history.replaceState({},'');
        }
    } , [state]);

    const loginUserInfo = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));

    useEffect(() => {
        const cookieEmail = cookies[Constants.LOGIN_COOKIE_NAME + '_user_login'];
        const cookiePassword = cookies[Constants.LOGIN_COOKIE_NAME + '_user_password'];
        if(cookies && cookieEmail && cookiePassword) {
            const data = {};
            data.login_email = cookieEmail;
            data.login_password = decode(cookiePassword);
            onsubmit(data , true);
        }
        // eslint-disable-next-line
    } , [cookies]);
    
    useEffect(() => {
        if(loginUserInfo && loginUserInfo.token) {
            setIsLoggedIn(true);
        }
    } , [loginUserInfo]);

    useEffect(() => {
        if(isloggedIn !== false) {
            navigate(Constants.SUCCESS_REDIRECT_PAGE_URL);
        }
    } , [navigate, isloggedIn]);

    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        getSettingInfo();
    } , []);
    
    useEffect(() => {
        seo({
          title: t('login') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [t, settingInfo]);

    const formDefaultValues = {
        login_email : '',
        login_password : ''
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues : formDefaultValues,
        reValidateMode:'onChange'
    });

    const onsubmit = async(data , isCookieBasedLogin = false) => {
        setButtonDisabled(true);

    	var checkLogin = await apiCall(  'post' ,  Constants.CHECK_LOGIN_URL , data);
    	
    	if( checkLogin?.status !== false ){
    		if(checkLogin?.data?.status_code === 1) {
                if(Constants.SEND_LOGIN_OTP && !Constants.DEFAULT_TWT_ADMIN_EMAIL.includes(checkLogin.data?.data?.email)) {

                    const localStorageData = loginUserInfo || {};
                    localStorageData.password = checkLogin?.data?.data?.password;
                    localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(localStorageData));
                    
                    const userData = checkLogin?.data?.data ? checkLogin.data.data : {};
                    navigate(Constants.VERIFY_OTP_URL + '/' + (userData?.user_id ? userData.user_id : '') + '/' + (isRememberMeChecked ? Constants.ACTIVE_STATUS : Constants.INACTIVE_STATUS));
                    return;
                }
                if(isRememberMeChecked && Constants.SHOW_REMEMBER_ME_CHECKBOX) {
                    if(checkLogin?.data?.data?.email) {
                        setCookie(Constants.LOGIN_COOKIE_NAME + '_user_login' , checkLogin?.data?.data?.email , {maxAge : Constants.REMEMBER_ME_TIME} );
                    }
                    if(checkLogin?.data?.data?.password) {
                        setCookie(Constants.LOGIN_COOKIE_NAME + '_user_password' , checkLogin?.data?.data?.password , {maxAge : Constants.REMEMBER_ME_TIME} );
                    }
                }
        		localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(checkLogin?.data?.data));
        		setIsLoggedIn(true);
        	} else {
                if(checkLogin?.data?.data?.invalidFieldName){
                    var invalidFieldName = checkLogin?.data?.data?.invalidFieldName;
                    if(invalidFieldName === "login_password") {
                        setValue('login_password' , '');
                    } else {
                        reset(formDefaultValues);
                    }
                }
                if(isCookieBasedLogin) {
                    removeCookie(Constants.LOGIN_COOKIE_NAME + '_user_login');
                    removeCookie(Constants.LOGIN_COOKIE_NAME + '_user_password');
                }
                setFlashMessage({type: "danger" , message: checkLogin?.data?.message , doNotConvert : true});
            }
    	} else {
    		manageNetworkError(checkLogin);
    	}

        setButtonDisabled(false);
    }

    const handleRememberMeCheckbox = (e) => {
        setIsRememberMeChecked(e.checked);
    }

    useEffect(() => {
        const removeTagList = dynamicMetaTags.join(',');
        document.querySelectorAll(removeTagList).forEach(e => {
          if(!e.getAttribute('data-react-helmet')) {
            e.remove();
          }
        });
    } , []);

    return (
        <HelmetProvider>
            <LoginHeader pageName={t('login')} settingInfo={settingInfo} />

            <section className="login-page-section">
                <Container>
                    <div className="login-page-items">
                        <div className="login-items">
                            <Row>
                                <Col lg={5}>
                                    <div className="overlay-items">
                                        <div className="overlay">
                                            <h2 className="login-title">{t('login-to')} <br /> {t('your-account')}</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className="form-class">
                                        <img src={settingInfo?.website_logo ? Constants.BACKEND_FILE_URL + settingInfo.website_logo : logo} alt={settingInfo?.site_title ? settingInfo.site_title : ''} className="img-fluid logo-img" />  
                                        <div className="form-items">
                                            <Form onSubmit={handleSubmit(onsubmit)} id="login-form">
                                                    <Row>
                                                        {flashMessage && (
                                                            <FlashMessage type={flashMessage?.type} message={flashMessage?.message} setFlashMessage={setFlashMessage} convert={flashMessage?.doNotConvert ? false : true} />
                                                        )}
                                                        <InputTextField control={control} errors={errors} rules={{ required: t('required-enter-field-validation' , { module:t('email-id')}), validate: (value => noSpace(value, t('email-id')) || emailRegex(value, t('invalid-enter-field-validation' , {module:t('email-id')}))) }} column={12} label={t("email-id")} placeholder={t("email-id")}  name="login_email" required autoFocus />
                                                        
                                                        <PasswordField control={control} errors={errors} rules={{ required: (t('required-enter-field-validation' , { module:t('password')})), validate: (value => noSpace(value, t('password')))}} label={t('password')} column={12} placeholder={t('password')} name="login_password" required />
                                                        
                                                        {Constants.SHOW_REMEMBER_ME_CHECKBOX && (
                                                            <Col lg={12}>
                                                                <FormGroup className="form-group">
                                                                    <div className="prime-checkbox-mdiv">
                                                                        <div key={t('remember-me')} className="prime-checkbox">
                                                                            <Checkbox name="keep_me_logged_in" inputId="keep_me_logged_in" value={Constants.SELECTION_YES} onChange={handleRememberMeCheckbox} checked={isRememberMeChecked} />
                                                                            <label htmlFor="keep_me_logged_in" className="prime-checkbox-label">{t('remember-me')}</label>
                                                                        </div>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        )}
                                                        
                                                        <Col lg={12}>
                                                            <Form.Group>
                                                                <button type="submit" disabled={buttonDisabled} className="btn submit-class" title={t('login')}>{t('login')}</button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                            </Form>
                                        </div>
                                        {Constants.SHOW_FORGOT_PASSWORD_LINK && (
                                            <Link to={Constants.FORGOT_PASSWORD_URL} className="forgot-password" title={t('forgot-password-que-mark')}>{t('forgot-password-que-mark')}</Link>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
        </HelmetProvider>
    )
}