import React, { useEffect, useState } from "react";
import "../../assets/css/flaticon-uicons/css/all/all.css";
import Header from './includes/header';
import Footer from './includes/footer';
import { seo } from '../../seo';
import { useTranslation } from "react-i18next";
import * as Constants from '../../config/constants';
import { useNavigate } from "react-router-dom";
import { apiCall , dynamicMetaTags, manageNetworkError, valueCheck } from "../../assets/js/common";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import icon from "../../assets/images/icon.png";
import logo from "../../assets/images/logo.png";

export default function Layout({ children , pageName , renderReq = false}) {
    const { t } = useTranslation();
    const [settingInfo , setSettingInfo] = useState([]);
    const navigate = useNavigate();

    const storageEventHandler = (e) => {
      if(e.key === (Constants.LOGIN_COOKIE_NAME) && e.oldValue && !e.newValue) {
        navigate(Constants.LOGIN_URL);
      }

      const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
      if(!user || !user?.token) {
        navigate(Constants.LOGIN_URL);
      }
      
      const exceptions = JSON.parse(localStorage.getItem(Constants.ERROR_COOKIE_NAME));
      if(exceptions && exceptions?.ticket_no) {
        navigate(Constants.EXCEPTION_PAGE_URL , {state : { ticketNo : exceptions?.ticket_no }});
        localStorage.removeItem(Constants.ERROR_COOKIE_NAME);
      }
    }

    useEffect(() => {
      window.addEventListener("storage" , storageEventHandler)
      return () => {
        window.removeEventListener("storage" , storageEventHandler);
      }
      // eslint-disable-next-line
    } , []);
    
    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
  
    useEffect(() => {
        if(!user || !user.token) {
          navigate(Constants.LOGIN_URL);
        }
    } , [navigate, user]);

    const getSettingInfo = async () => {
      const getSettingData = await apiCall( 'get' , Constants.SETTING_URL , null , false);

      if(getSettingData?.status !== false) {
          if(getSettingData?.data?.data) {
              setSettingInfo(getSettingData?.data?.data?.recordInfo);
          }
      } else {
          manageNetworkError(getSettingData);
      }
    }

    useEffect(() => {
      if(user && user?.token) {
        getSettingInfo();
      }
      // eslint-disable-next-line
    } , [renderReq]);

    useEffect(() => {
      seo({
        title: pageName + ' | ' + (settingInfo?.v_site_title ? settingInfo.v_site_title : ''),
      });
    }, [t, pageName, settingInfo]);

    useEffect(() => {
      const removeTagList = dynamicMetaTags.join(',');
      document.querySelectorAll(removeTagList).forEach(e => {
        if(!e.getAttribute('data-react-helmet')) {
          e.remove();
        }
      });
    } , []);
    
    return (
      <>
      {user && user?.token && (
        <HelmetProvider>
          <Helmet>
            <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + valueCheck(settingInfo?.v_site_title)} />
            <meta name='description' content={ valueCheck(settingInfo?.v_site_description) } />
            <meta name='keywords' content={ valueCheck(settingInfo?.v_site_keywords) } />
            <meta name='author' content={ valueCheck(settingInfo?.v_meta_author)  } />
            <meta name='copyright' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta name='publisher' content={ valueCheck(settingInfo?.v_meta_author)  } />
            
            <meta property='og:title' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta property='og:description' content={valueCheck(settingInfo?.v_site_description) } />
            <meta property="og:url" content={Constants.SITE_URL} />
            <meta property='og:site_name' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta property='og:image' content={settingInfo?.v_website_og_icon ? (Constants.BACKEND_FILE_URL + settingInfo.v_website_og_icon) : icon} />

            <meta property="twitter:url" content={Constants.SITE_URL} />
            <meta property='twitter:title' content={valueCheck(settingInfo?.v_site_title)} />
            <meta property='twitter:description' content={ valueCheck(settingInfo?.v_site_description) } />
            <meta property='twitter:image' content={settingInfo?.v_website_logo ? (Constants.BACKEND_FILE_URL + settingInfo.v_website_logo) : logo} />
            
            <link rel="icon" href={settingInfo?.v_website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon) : icon} />
            <link rel="apple-touch-icon" href={settingInfo?.v_website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon) : icon} />  
            
            <meta name="theme-color" content={Constants.ADMIN_THEME_COLOR}></meta>
          
          </Helmet>
          <Header settingInfo={settingInfo}/>
            <main className="backend-content" id="backend-content">{children}</main> 
          <Footer settingInfo={settingInfo}/>
        </HelmetProvider>
      )}
      </>
    )
}