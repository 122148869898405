import {React, lazy, Suspense} from 'react';
import { Route } from "react-router-dom";
import * as Constants from "../config/constants";
import Login from '../pages/admin/login';
import ForgotPassword from '../pages/admin/forgot-password';
import ResetPassword from '../pages/admin/reset-password';
import VerifyOtp from '../pages/admin/verify-otp';
import Logout from '../pages/admin/logout';
import Exception from "../pages/errors/exception-page";

const Dashboard = lazy(() => import('../pages/admin/dashboard/dashboard'));
const LoginHistory = lazy(() => import('../pages/admin/login-history/login-history'));
const ChangePassword = lazy(() => import('../pages/admin/dashboard/change-password'));
const Settings = lazy(() => import('../pages/admin/settings/settings'));
const User = lazy(() => import('../pages/admin/users/users'));
const AddUser = lazy(() => import('../pages/admin/users/add-users'));
const Lookup = lazy(() => import('../pages/admin/lookup/lookup'));

const BackendRoutes = [

    // <--- if only admin panel then uncomment this route --->
    <Route key="backend-0" path={Constants.LANDING_PAGE_URL} element={<Login />} />,
    // <--- if only admin panel then uncomment this route --->

	<Route key="backend-1" path={Constants.LOGIN_URL} element={<Login />} />,
    <Route key="backend-2" path={Constants.LOGOUT_URL} element={<Logout />} />,
    <Route key="backend-3" path={Constants.DASHBOARD_PAGE_URL} element={<Suspense><Dashboard pageName="Dashboard"/></Suspense>} />,
    <Route key="backend-4" path={Constants.LOGIN_HISTORY_URL} element={<Suspense><LoginHistory pageName="Login History" /></Suspense>} />,
    <Route key="backend-5" path={Constants.CHANGE_PASSWORD_PAGE_URL} element={<Suspense><ChangePassword pageName="Change Password" /></Suspense>} />,
    <Route key="backend-6" path={Constants.SETTING_URL} element={<Suspense><Settings pageName="Settings" /></Suspense>} />,
    <Route key="backend-7" path={Constants.LOCATION_URL} element={<Suspense><Lookup /></Suspense>} />,
    <Route key="backend-8" path={Constants.USER_URL} element={<Suspense><User pageName="Users" /></Suspense>} />,
    <Route key="backend-9" path={Constants.USER_URL + '/create'} element={<Suspense><AddUser /></Suspense>} />,
    <Route key="backend-10" path={Constants.USER_URL + '/edit/:recordId'} element={<Suspense><AddUser /></Suspense>} />,
	<Route key="backend-11" path={Constants.TYPE_URL} element={<Suspense><Lookup /></Suspense>} />,
	<Route key="backend-12" path={Constants.FORGOT_PASSWORD_URL} element={<ForgotPassword />} />,
	<Route key="backend-13" path={Constants.RESET_PASSWORD_URL + '/:encodedData'} element={<ResetPassword />} />,
	<Route key="backend-14" path={Constants.VERIFY_OTP_URL + '/:userId/:isRememberMeChecked'} element={<VerifyOtp />} />,
    <Route key="backend-15" path={Constants.EXCEPTION_PAGE_URL} element={<Exception pageName="Internal Server Error" />} />,

];

export default BackendRoutes;