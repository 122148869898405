import { React} from 'react';
import { Route, Routes } from "react-router-dom";
import BackendRoute from './routes/backend-route';
import DesignRoute  from './routes/design-route';
import AdminError404 from './pages/errors/admin/404';
import './assets/js/common.js';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import './assets/css/app.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

// <--- if only admin panel then uncomment this import --->
import './assets/css/admin-app.css';
// <--- if only admin panel then uncomment this import --->

function App() {

  return (
    <div className="App">
    	<Routes>
    		{DesignRoute}
    		{BackendRoute}
			
			{/* <--- if only admin panel then uncomment this route ---> */}
			<Route key="page-not-found" path="*" element={<AdminError404 />} />
			{/* <--- if only admin panel then uncomment this route ---> */}    	
		</Routes>
    </div>
  );
}

export default App;
