var hostName = window.location.hostname;
var protocol = window.location.protocol;
var port = window.location.port;
var siteName = '';
// eslint-disable-next-line
var frontendFolder = '/frontend-react/';
var backendFolder = '/backend/';

export const BASE_PATH = '/';

//encryption keys
export const ENCRYPTION_KEY = "PRAHEAL";
export const SITE_TITLE = 'Praheal';

export const LOGIN_COOKIE_NAME = ENCRYPTION_KEY;
export const ERROR_COOKIE_NAME = ENCRYPTION_KEY + "_ERROR";

// Use Api Base Url & Backend File Url As Per Your Backend Language

// Use In Laravel Or CI API
export const API_BASE_URL = protocol + "//" + hostName + siteName + backendFolder + 'api/';

// Use In Node API
// Change port as well base on your node api
// export const API_BASE_URL = protocol + "//" + hostName + ':3001/api/';

// Use In Laravel API
export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + 'storage/app/uploads/';

// Use In CI API
// export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + 'uploads/';

// Use In Node API
// export const BACKEND_FILE_URL = protocol + "//" + hostName + siteName + backendFolder + 'storage/uploads/';

// Change port as well base on your react app
export const SITE_URL = protocol + "//" + hostName + ':'+ port +'/';

export const LANGUAGE_FOLDER_URL = SITE_URL; 

//pagination 
export const PER_PAGE = 10;
export const SELECT_ALL_ROWS_TEXT = 'ALL';
export const PER_PAGE_OPTIONS = [10,25,50];

//show confirm box 
export const SHOW_CONFIRM_BOX = true;

// Admin Panel Url Prefix 
export const ADMIN_PANEL_URL_PREFIX = '/admin';

//show developer settings
export const SHOW_DEVELOPER_SETTINGS = 0;

// only Admin Panel 
export const ONLY_ADMIN_PANEL = true;

//send OTP
export const SEND_LOGIN_OTP = false;
export const OTP_LENGTH = 6;
export const LOGIN_RESEND_OTP_TIME = 30; //Enter this value in seconds

//Default TWT Admin Email
export const DEFAULT_TWT_ADMIN_EMAIL = ["info@thewildtigers.com"];

export const SHOW_FORGOT_PASSWORD_LINK = true;
export const SHOW_REMEMBER_ME_CHECKBOX = false;
export const REMEMBER_ME_TIME = 3600 //Enter this value in seconds