import React from "react";
import { Helmet } from 'react-helmet-async';
import * as Constants from "../../../config/constants";
import { valueCheck } from "../../../assets/js/common";
import icon from "../../../assets/images/icon.png";
import logo from "../../../assets/images/logo.png";

export default function LoginHeader ({ pageName = "", settingInfo = {} }) {

    return (
        <Helmet>
            <meta name='title' content={pageName + ' | ' + valueCheck(settingInfo?.site_title)} />
            <meta name='description' content={ valueCheck(settingInfo?.site_description) } />
            <meta name='keywords' content={ valueCheck(settingInfo?.site_keywords) } />
            <meta name='author' content={ valueCheck(settingInfo?.meta_author)  } />
            <meta name='copyright' content={ valueCheck(settingInfo?.site_title) } />
            <meta name='publisher' content={ valueCheck(settingInfo?.meta_author)  } />
            
            <meta property='og:title' content={ valueCheck(settingInfo?.site_title) } />
            <meta property='og:description' content={valueCheck(settingInfo?.site_description) } />
            <meta property="og:url" content={Constants.SITE_URL} />
            <meta property='og:site_name' content={ valueCheck(settingInfo?.site_title) } />
            <meta property='og:image' content={settingInfo?.website_og_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_og_icon) : icon} />

            <meta property="twitter:url" content={Constants.SITE_URL} />
            <meta property='twitter:title' content={valueCheck(settingInfo?.site_title)} />
            <meta property='twitter:description' content={ valueCheck(settingInfo?.site_description) } />
            <meta property='twitter:image' content={settingInfo?.website_logo ? (Constants.BACKEND_FILE_URL + settingInfo.website_logo) : logo} />
            
            <link rel="icon" href={settingInfo?.website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon) : icon} />
            <link rel="apple-touch-icon" href={settingInfo?.website_fav_icon ? (Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon) : icon} />  

            <meta name="theme-color" content="#000000" />
        </Helmet>
    )
}