import React, { useEffect, useState } from "react";
import "../../assets/css/error-pages.css";
import exception from '../../assets/images/exception.png';
import { Link, useLocation } from "react-router-dom";
import { apiCall, manageNetworkError } from "../../assets/js/common";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import { seo } from "../../seo";

export default function ExceptionPage({ pageName }) {

    const { t } = useTranslation();
    const { state } = useLocation();
    const [settingInfo, setSettingInfo] = useState([]);
    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        getSettingInfo();
    } , []);

    useEffect(() => {
        seo({
          title: t('internal-server-error') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [t, settingInfo]);

    return (
        <section className="custom-error-section bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <img src={exception} alt="exception" className="img-fluid man" />
                            </div>
                            <div className="col-md-6">
                                <h2 className="error-text mb-4">Oops!!!</h2>
                                <h4 className="text-muted mb-4">Something went wrong..!!</h4>
                                <p className="text-muted h6 mb-4">Please contact Technical Team with Ticket No. #{state?.ticketNo ? state.ticketNo : "TCKT-0"} to know more... </p>
                                <Link to={"/"} className="common-link home-page-link">Go Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}