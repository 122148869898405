import React from "react";
import {
  Card,
  Collapse,
} from "react-bootstrap";

const FilterWrapper = ({children, data}) => {

  return (
    <Collapse in={data} id="filter-collapse" className="common-filter-collapse">
      <div>
        <Card className="common-filter-card">
          <Card.Body>
            {children}
          </Card.Body>
        </Card>
      </div>
    </Collapse>
  );
};
export default FilterWrapper;