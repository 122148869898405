import React from "react";
import { Col, Form, FormGroup } from "react-bootstrap";
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

const PasswordField = ( props = {} ) => {
	const fieldName = props?.name;
	const formErrors = props?.errors;
	return (
        <Col lg={props.column}>
        	<FormGroup className="form-group">
        		<Form.Label className="control-label">{props?.label}{ props?.required ? <span className="text-danger">*</span> : '' }</Form.Label>
        		<Controller
        			name={props?.name}
        			control={props?.control}
	            	rules={ props?.rules ?  props?.rules : "" }
	        	    render={({ field, fieldState }) => (
	        	        <>
	        	            <Password 
	        	            	{...field}
	        	            	placeholder={props?.placeholder}
								value={field?.value || ''}
								onChange={(e) => {
									if(props?.onFieldChange) {
										props.onFieldChange(e);
									}
									field.onChange(e.target.value);
								}} 
	        	            	className={classNames({ 'p-invalid': fieldState.error } , ( props?.classList ? props?.classList : '' ) )} 
	        	            	feedback={props?.feedback ? props?.feedback : false } 
								toggleMask
								autoComplete={props?.autoComplete || "new-password"}
								autoFocus={props?.autoFocus}
	        	            />
							{ ( formErrors && formErrors[fieldName]?.message ) && ( 
								<Form.Label className="invalid-input" htmlFor={props?.name}>{formErrors[fieldName]?.message}</Form.Label> 
							)}
	        	        </>
	        	    )}
	        	/>
        	</FormGroup>
        </Col>
    )
}
export default PasswordField