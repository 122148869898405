import React, {useState} from "react";
import Layout from '../../admin/layout';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Constants from "../../../config/constants";
import ListingPageLayout from "../../../component/PageComponents/ListingPageLayout";
import FilterWrapper from "../../../component/Utilities/FilterWrapper";
import TableWrapper from "../../../component/Utilities/TableWrapper";
import FilterActionBtns from "../../../component/Utilities/FilterActionBtns";
import InputTextField from "../../../component/InputTextField";
import DropdownField from "../../../component/DropdownField";
import {
	Button,
	Row,
	Form,
	Table,
} from "react-bootstrap";

export default function DesignUsers({pageName}) {
	const { t } = useTranslation();
	const [filterOpen, setFilterOpen] = useState(false);

	const {control, formState: { errors }} = useForm();

  	const statusOptions = [
		{ label: t("select"), value: '' },
		{ label: t("enable"), value: Constants.ENABLE_STATUS_TEXT },
		{ label: t("disable"), value: Constants.DISABLE_STATUS_TEXT },
	];

  return (
    <Layout pageName={pageName}>
		<ListingPageLayout 
			pageTitle={t('users')}
			totalRecords="2"
			actionBtnData={[
				{url: Constants.ADMIN_PANEL_URL_PREFIX + '/users/design-create', title: t("add-user"), icon: 'fas fa-plus twt-add-icon', show: true},
			]}
			filter={true} filterOpen={filterOpen} setFilterOpen={setFilterOpen} 
		>
			<FilterWrapper data={filterOpen}>
				<Row>
					<InputTextField control={control} errors={errors} column={3} label={t("search-by")} placeholder={t('search-by') + ' ' + t('name') + ', ' + t('email-id') + ', ' + t('mobile-no')}  name="search_by" />

					<DropdownField control={control} errors={errors} placeholder={t('select')} column={2} label={t('status')}  name="search_status" showClear={true} filter={true} options={statusOptions} />

					<FilterActionBtns resetOnClick={() => setFilterOpen(!filterOpen)} />
				</Row>
			</FilterWrapper>

			<TableWrapper>
				<div className="table-responsive twt-fewcol-sticky">
					<Table bordered hover size="sm">
						<thead className="twt-table-header">
							<tr>
								<th className="sr-col">{t('sr-no')}</th>
								<th>{t('name')}</th>
								<th>{t('email-id')}</th>
								<th>{t('mobile-no')}</th>
								<th className="status-col">{t('status')}</th>
								<th className="actions-col">{t('actions')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="sr-col">1</td>
								<td>Lorem, ipsum dolor.</td>
								<td>demo@gmail.com</td>
								<td>9999999999</td>
								<td><Form.Check	type="switch" className="twt-custom-switch" id="customSwitches1" label="Disable"/></td>
								<td className="actions-col">
									<div className="actions-col-div">
										<Link title={t('edit')} to={void(0)} className="btn btn-sm action-btn edit-btn"><i className="fa-fw fi fi-rr-pencil"></i></Link>
										<Button type='button' title={t('delete')} variant="" size="sm" className="action-btn delete-btn"><i className="fi fi-rr-trash fa-fw"></i></Button>
									</div>
								</td>
							</tr>
							<tr>
								<td className="sr-col">2</td>
								<td>Lorem, ipsum dolor.</td>
								<td>demo@gmail.com</td>
								<td>9999999999</td>
								<td><Form.Check	type="switch" className="twt-custom-switch" id="customSwitches2" label="Disable"/></td>
								<td className="actions-col">
									<div className="actions-col-div">
										<Link title={t('edit')} to={void(0)} className="btn btn-sm action-btn edit-btn"><i className="fa-fw fi fi-rr-pencil"></i></Link>
										<Button type='button' title={t('delete')} variant="" size="sm" className="action-btn delete-btn"><i className="fi fi-rr-trash fa-fw"></i></Button>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</TableWrapper>
		</ListingPageLayout>
    </Layout>
  );
}