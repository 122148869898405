import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Constants from '../../config/constants';
import { apiCall , manageNetworkError , alertifyMessage } from "../../assets/js/common";
import { useCookies } from 'react-cookie';

export default function Logout() {
	const navigate = useNavigate();
	var isFirstRender = true;
	// eslint-disable-next-line
	const [cookies , setCookie , removeCookie] = useCookies(Constants.LOGIN_COOKIE_NAME);
	
	const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));

	useEffect(() => {
        if(!user || !user.token) {
          navigate(Constants.LOGIN_URL);
        }
    } , [navigate, user]);

	const userLogout = async () => {
	
		const response = await apiCall('get' , Constants.LOGOUT_URL);
		if (response?.status !== false) {
			if (response?.data?.status_code === 1) {
				removeCookie(Constants.LOGIN_COOKIE_NAME + '_user_login');
                removeCookie(Constants.LOGIN_COOKIE_NAME + '_user_password');
				localStorage.removeItem(Constants.LOGIN_COOKIE_NAME)
				navigate(Constants.LOGIN_URL);
			} else {
				alertifyMessage("error", response?.data?.message);
			}
		} else {
			manageNetworkError(response);
		}
	}
	useEffect(() => {
		if(isFirstRender && user && user?.token) {
			// eslint-disable-next-line
			isFirstRender = false;
			userLogout();
		}
    } , []);
}