import React, { useEffect, useState } from "react";
import "../../../assets/css/error-pages.css";
import * as Constants from "../../../config/constants";  
import { apiCall, manageNetworkError } from "../../../assets/js/common";
import { seo } from "../../../seo";
import { useTranslation } from "react-i18next";

export default function Error404() {

    const {t} = useTranslation();
    const [settingInfo, setSettingInfo] = useState([]);
    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        getSettingInfo();
    } , []);
    
    useEffect(() => {
        seo({
            title: t('page-not-found') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [ t , settingInfo ]);

    return (
        <div style={{background: "#fff"}}>
            <div className="error-page-sec-admin" style={{height: "100vh"}}>
                <div className="content">
                    <h4 className="error-text" style={{color: "rgb(33, 37, 41)", letterSpacing: "0"}}>404</h4>
                    <p className="text">The page you requested was not found.</p>
                </div>
            </div>
        </div>
    );
}