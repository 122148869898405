import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Constants from '../../config/constants';
import { Link, useNavigate, useParams } from "react-router-dom";
import { seo } from "../../seo";
import { alertifyMessage, apiCall, manageNetworkError, noSpace, onlyNumber, dynamicMetaTags } from "../../assets/js/common";
import { useTranslation } from "react-i18next";
import {
	Container,
	Row,
	Col,
	Form,
} from "react-bootstrap";
import InputTextField from "../../component/InputTextField";
import { useCookies } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import FlashMessage from "../../component/FlashMessage";
import LoginHeader from "./guest/login-header";

export default function VerifyOtp() {

    const { t } = useTranslation( [ "translation", "errorMessage" ]);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [cookies , setCookie] = useCookies();
    const { userId , isRememberMeChecked } = useParams();
    const [isloggedIn , setIsLoggedIn] = useState(false);
    const [settingInfo, setSettingInfo] = useState([]);

    const [flashMessage , setFlashMessage] = useState(null);

    const loginUserInfo = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
  
    useEffect(() => {
        if(loginUserInfo && loginUserInfo?.token) {
            setIsLoggedIn(true);
        }
    } , [loginUserInfo]);

    useEffect(() => {
        if(isloggedIn !== false) {
            navigate(Constants.SUCCESS_REDIRECT_PAGE_URL);
        }
    } , [navigate, isloggedIn]);

    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        getSettingInfo();
    } , []);

    useEffect(() => {
        seo({
          title: t('verify-otp') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [t, settingInfo]);

    const formDefaultValues = {
        login_otp : ''
    }
    
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues : formDefaultValues,
        reValidateMode:'onChange'
    });

    const onsubmit = async(data) => {
        data.user_id = userId;
        const response = await apiCall('post' , Constants.CHECK_OTP_URL , data);

        if(response?.status !== false) {
            if(response?.data?.status_code === 1) {
                if(parseInt(isRememberMeChecked) === Constants.ACTIVE_STATUS && Constants.SHOW_REMEMBER_ME_CHECKBOX) {
                    if(response?.data?.data?.email) {
                        setCookie(Constants.LOGIN_COOKIE_NAME + '_user_login' , response?.data?.data?.email , {maxAge : Constants.REMEMBER_ME_TIME} );
                    }
                    if(loginUserInfo?.password) {
                        setCookie(Constants.LOGIN_COOKIE_NAME + '_user_password' , loginUserInfo?.password , {maxAge : Constants.REMEMBER_ME_TIME} );
                    }
                }
                localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(response?.data?.data));
                setIsLoggedIn(true);
            } else {
                setFlashMessage({type: "danger" , message: response.data?.message , doNotConvert : true});
                // alertifyMessage('error' , response.data?.message);
            }
        } else {
            manageNetworkError(response);
        }
        reset(formDefaultValues);
    }

    const [isDisabled , setIsDisabled] = useState(true);
    const [otpTimer , setOtpTimer] = useState('');
    const [remainingTime , setRemainingTime] = useState(Constants.LOGIN_RESEND_OTP_TIME);
    
    const resendOTP = async () => {
        setIsDisabled(true);

        if(isDisabled !== false){
            return true;
        }

        var data = {};
        data.user_id = userId;
        const response = await apiCall('post' , Constants.RESEND_OTP_URL , data);

        if(response?.status !== false) {
            if(response?.data?.status_code === 1) {
                alertifyMessage('success' , response.data?.message);
                setRemainingTime(Constants.LOGIN_RESEND_OTP_TIME);
            } else {
                alertifyMessage('error' , response.data?.message);
            }
        } else {
            manageNetworkError(response);
        }
    }

    const timer = () => {
        var m = Math.floor(remainingTime / 60);
        var s = remainingTime % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        setOtpTimer(' (' + m + ':' + s + ')');
        setRemainingTime(remainingTime - 1);
    }

    useEffect(() => {
        if(remainingTime === Constants.LOGIN_RESEND_OTP_TIME) {
            timer();
        } else if(remainingTime >= 0) {
            setTimeout(() => {
                timer();
            }, 1000);
        } else {
            setIsDisabled(false);
            setOtpTimer('');
        }
        // eslint-disable-next-line
    } , [remainingTime]);

    useEffect(() => {
        const removeTagList = dynamicMetaTags.join(',');
        document.querySelectorAll(removeTagList).forEach(e => {
          if(!e.getAttribute('data-react-helmet')) {
            e.remove();
          }
        });
    } , []);

    return (

        <HelmetProvider>
            <LoginHeader pageName={t('verify-otp')} settingInfo={settingInfo} />
            <section className="login-page-section">
                <Container>
                    <div className="login-page-items otp-page">
                        <div className="login-items">
                            <Row>
                                <Col lg={5}>
                                    <div className="overlay-items">
                                        <div className="overlay flex-column">
                                            <h2 className="login-title pb-3">{t('verify-otp')}</h2>
                                            <p className="w-75 text-center">For Security Purposes, Only After OTP Verification You Will Be Able To Access This System.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className="form-class">
                                        <div className="password-icon">
                                            <i className="fa fa-lock"></i>
                                        </div>
                                        <div className="form-items">
                                            <Form onSubmit={handleSubmit(onsubmit)} id="verify-otp-form">
                                                <Row>
                                                    {flashMessage && (
                                                        <FlashMessage type={flashMessage?.type} message={flashMessage?.message} setFlashMessage={setFlashMessage} convert={flashMessage?.doNotConvert ? false : true} />
                                                    )}
                                                    <InputTextField control={control} errors={errors} rules={{ required: t('required-enter-field-validation' , { module:t('otp')}), validate: (value => noSpace(value, t('otp'))), minLength: { value: Constants.OTP_LENGTH, message: t('common-minlength-message', { value: Constants.OTP_LENGTH }) } }} maxLength={Constants.OTP_LENGTH} column={12} label={t("enter-otp")} placeholder={t("enter-otp")} name="login_otp" onFieldChange={onlyNumber} onKeyUp={onlyNumber} autoFocus required />
                                                    <p className="px-0">
                                                        {t('didnt-get-otp')} 
                                                        <Link className={"otp-resend " + (isDisabled ? "twt-disabled" : '')} onClick={resendOTP}>{t('resend-otp')}</Link>
                                                        <span className="otptimer">{otpTimer}</span>
                                                    </p>
                                                    <Col lg={12}>
                                                        <Form.Group>
                                                            <button type="submit" className="btn submit-class" title={t('verify-otp')}>{t('verify-otp')}</button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                        <Link to={Constants.LOGIN_URL} className="back-to-login" title={t('back-to-login')}><i className="fa fa-arrow-left me-2"></i> {t('back-to-login')}</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>            
        </HelmetProvider>

    )
}