import React from "react";
import { ucWord } from "../assets/js/common";

const FlashMessage = ({
    type,
    message,
    convert = true,
    setFlashMessage
}) => {
	return (
        <div className={"alert alert-" + type + " alert-dismissible text-center"} role="alert">
            <button type="button" className="btn-close" aria-label="Close" onClick={() => setFlashMessage(null)} >
                <span aria-hidden="true"></span>
            </button> {convert !== false ? ucWord(message) : message} 
        </div>
    )
}
export default FlashMessage