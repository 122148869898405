import React from "react";
import axios from "axios";
import * as Constants from "../../config/constants";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import CryptoJS from "crypto-js";
import { Link } from 'react-router-dom'
import {
	Button,
	Form
} from "react-bootstrap";
var moment = require('moment');


// Designer 
export function handleActiveClass() {
    const currentPath = window.location.pathname;

    if (currentPath) {
        const dropdownNavLinks = document.querySelectorAll('.dropdown .nav-link, .dropdown .nav-items');
        const navLinks = document.querySelectorAll('.nav-items, li .nav-link');

        navLinks.forEach((navLink) => {
            const href = navLink.getAttribute('href');
            if (href === currentPath) {
                navLink.closest('.nav-items-class, .nav-item')?.classList.add('active');
            }
        });

        dropdownNavLinks.forEach((dropdownLink) => {
            const href = dropdownLink.getAttribute('href');
            if (href === currentPath) {
                dropdownLink.closest('.nav-items.dropdown')?.querySelector('.dropdown-toggle')?.classList.add('active');
                dropdownLink.parentElement.classList.add("active");
                dropdownLink.closest('.sub-dropdown-collapse')?.classList.add('show');
                dropdownLink.closest('.items-drodown-menu')?.classList.add('show');
                dropdownLink.closest('.dropdown-sub-megamenu')?.classList.add('active');
                dropdownLink.closest('.nav-items-class').classList.add('active');

                let parentMenu = dropdownLink.closest('.nav-items.dropdown');
                while (parentMenu) {
                    parentMenu.classList.add('active');
                    const collapseElement = parentMenu.querySelector('.collapse.navbar-collapse');
                    if (collapseElement) {
                        collapseElement.classList.add('show');
                    }
                    parentMenu = parentMenu.parentElement.closest('.nav-items.dropdown');
                }
            }
        });
    }
	
	document.querySelectorAll('.dropdown > .first-menu').forEach(a => {
        a.addEventListener('click', (e) => {
            if (window.innerWidth < 992) {
                e.preventDefault();
            }

            const dropdownMenus = document.querySelectorAll('.navbar-collapse');
            dropdownMenus.forEach(menu => {
                if (menu.classList.contains('show')) {
                    menu.classList.remove('show');
                }
            });
            e.stopPropagation();
        });
    });

		document.querySelectorAll('.sidebar .main-drodown-toggle').forEach((toggle) => {
			// Check if the click event listener has already been attached
			if (!toggle.hasEventListener) {
					toggle.addEventListener('click', (e) => {
							e.preventDefault();
							const parentLi = toggle.parentElement.closest('.nav-items.dropdown');
							parentLi.classList.toggle('active');
	
							// Remove "active" class from other menu items
							parentLi.parentElement.querySelectorAll('.nav-items.dropdown').forEach((sibling) => {
									if (sibling !== parentLi) {
											sibling.classList.remove('active');
									}
							});
	
							document.querySelectorAll('.nav-items-class.items-link').forEach((nonDropdownLink) => {
									nonDropdownLink.classList.remove('active');
							});
					});
	
					// Mark the element to indicate that the event listener has been attached
					toggle.hasEventListener = true;
			}
	});
	
}

export const toggleNav = () => {
  document.body.classList.toggle("nav-slide-open");
};

document.addEventListener("click", function(e) {
	if (window.innerWidth < 992 && !e.target.closest("#slide-toggle")) {
		document.body.classList.remove("nav-slide-open");
	}
});

window.addEventListener("scroll", function() {
	if (window.scrollY > 72) {
		document.querySelector(".twt-navbar")?.classList.add("fixed");
	} else {
		document.querySelector(".twt-navbar")?.classList.remove("fixed");
	}
});

export function showLoadingHandler(data) {
	setTimeout(()=>{
		if (data === true) {
			const parent = document.querySelector('.App');
			const newChild = `<div class="loader"><div class="loader--ring"><div></div><div></div><div></div><div></div></div></div>`;
			parent.insertAdjacentHTML('beforeend', newChild);
		}else if(data === false){
			const loader = document.querySelector(".loader");
			if (loader) {
				loader.parentNode.removeChild(loader);
			}
		}
	}, 10)
}

export function showLoader(){
	showLoadingHandler(true);
}
export function hideLoader(){
	showLoadingHandler(false);
}

export function modalBackdrop(){
	setTimeout(() => {
		const modals = document.querySelectorAll('.modal');
		const backdrops = document.querySelectorAll('.modal-backdrop');
		const computedZIndex = 1050 + 10 * modals.length;
	
		backdrops.forEach((backdrop, index) => {
			if (index === backdrops.length - 1) {
				const zIndex = parseInt(computedZIndex);
				backdrop.style.zIndex = zIndex;
			}
		});

		modals.forEach((modal, index) => {
			if (index === backdrops.length - 1) {
				const zIndex = parseInt(computedZIndex + 1);
				modal.style.zIndex = zIndex;
			}
		});
	}, 50);
}

// End Designer

export function checkServerSideValidation(validationErrors , setError){
	
	if( typeof(validationErrors) !== "undefined"  ){
		if( Array.isArray(validationErrors)){
			validationErrors.forEach ( (value)  => {
				setError( '' + value.field +'', {
					message: value.message,
				});
			});
		}else{
			alertifyMessage("error" , validationErrors )
		}
	}
	
}

export function valueCheck(value) {
    return ((value && value !== null && value !== "") ? value : '');
}

export function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
}

export const apiCall = async (method = 'get' ,  url ,  data = null , isLoader = true ) => { 
	url = url.replace(Constants.ADMIN_PANEL_URL_PREFIX + '/' , "/");
    let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    let client = axios.create({
		  baseURL: Constants.API_BASE_URL,
		  headers: {
	            'Access-Control-Allow-Origin': '*',
	            'Authorization': ((user && user.token) ? ("Bearer " + user.token) : undefined),
	            'Content-Type': 'multipart/form-data;',
	      },
	});
  
    var result = {};
	var response = {};
	if(isLoader !== false) {
		showLoader();
	}
	if( method === "get" ){
    	
    	try {
    		result =  client.get(url).then((responseData) => {
				if( responseData?.data?.status_code === 401 ){
					handleNoResponse();
				}
				if( responseData?.data?.status_code === 500 ) {
					handleException(responseData.data);
				}
				if( responseData?.data?.status_code === 404 ) {
					handlePageNotFound();
				}
				if(isLoader !== false) {
					hideLoader();
				}
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	if(isLoader !== false) {
					hideLoader();
				}
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	} catch (error) {
    		if(isLoader !== false) {
				hideLoader();
			}
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	
    	return result;
    } else if( method === "post" ){
    	try {
    		result =  client.post(url,data).then((responseData) => {
				if( responseData?.data?.status_code === 401 ){
					handleNoResponse();
				}
				if( responseData?.data?.status_code === 500 ) {
					handleException(responseData.data);
				}
				if( responseData?.data?.status_code === 404 ) {
					handlePageNotFound();
				}
    			if(isLoader !== false) {
					hideLoader();
				}
    			response.status = true;
    			response.data = responseData.data;
	            return response;
	        }).catch((error) => {
	        	if(isLoader !== false) {
					hideLoader();
				}
	        	response.status = false;
	        	response.data = error;
	            return response;
	        });
    	}catch (error) {
    		if(isLoader !== false) {
				hideLoader();
			}
    		response.status = false;
        	response.data = error;
        	return response;
    	}
    	return result;
    }
}

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary btn-ok";
alertify.defaults.theme.cancel = "btn btn-danger btn-cancel";
alertify.defaults.theme.input = "form-control";
export function alertifyMessage(type, message) {
	switch (type) {
		case "error":
			alertify.notify(message, "error", 5);
			break;
		case "success":
			alertify.notify(message, "success", 5);
			break;
		case "warning":
			alertify.notify(message, "warning", 5);
			break;
		case "info":
			alertify.notify(message);
			break;
		default:
			alertify.notify(message);
	}
}

export function manageNetworkError(response , t = null ) {
	
	var error_code = response?.data?.code ;
	if( error_code === "ERR_NETWORK"){
		alertifyMessage("error" , "Network Error. Please Contact System Administrator." );
	}
}

export function clientDateTime(dateTime) {
	return ((dateTime !== null && dateTime !== "") ? moment(dateTime).format('DD-MM-YYYY hh:mm:ss A') : '');
}

export function clientDate(date) {
	return ((date !== null && date !== "") ? moment(date, moment.ISO_8601).format("DD-MM-YYYY")  : '');
}

export function emailRegex(value, message = null) {
	if(value) {
		return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value) || message || "Please Enter Valid Email";
	}
}

export function mobileRegex(value , message = null) {
	if(value) {
		return (/^[6789]\d{9}$/).test(value) || message || "Please Enter Valid Mobile No.";
	}
}

export function baseName(path){
	return path.replace(/^.*(\\|\/|:)/, '');
}

export function onlyNumberWithSpaceAndPlusSign(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ +\d]/g, "");
}

export function ucWord( message ) {
	var str = '';
	str = message.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyDecimal(e) {
	var val = e.target.value;

	if (parseInt(val) === 0) {
		var newValue = val.replace(/^0+/, "");
		e.target.value = newValue;
		return;
	}

	if (isNaN(val)) {
		val = val.replace(/[^0-9.]/g, "");
		//if (val.split(".").length > 2) val = val.replace(/\.+$/, "");
	}
	e.target.value = val;
}

export function onlyNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "");
}

export function naturalNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "").replace(/^0+/g, "");
}

export function enumText(inputString){
	var str = inputString.replace(/_/g, ' ');
	str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyNumberWithSpace(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ \d]/g, "");
}

export function imagePreview(file) {
	var objectUrl = null;

	if(file !== null && file !== "" && file !== undefined) {
		objectUrl = URL.createObjectURL(file)
	}
	return objectUrl;
}

export const actionsDiv = (recordId , recordName , onEditClick , onDeleteClick , recordUrl = null  ,formType = null , role = null ) => {
	return (
		<div className="actions-col-div">
			{ (formType === 'form' ?  <Link tilte="Edit" to={recordUrl + '/edit/' + recordId} className="btn btn-sm action-btn edit-btn" title="Edit"><i className="fa-fw fi fi-rr-pencil"></i></Link> :  <button type="button" title="Edit" className="btn btn-sm action-btn edit-btn btn-blur" onClick={() => {onEditClick(recordId); focusOut()}}><i className="fi fi-rr-pencil"></i></button> ) }
			{( role !== Constants.ROLE_ADMIN ? <Button type="button" title="Delete" variant="" size="sm" className="action-btn delete-btn btn-blur" onClick={() => {onDeleteClick(recordId , recordName); focusOut()}}><i className="fi fi-rr-trash fa-fw"></i></Button> : '' )}
		</div>
	)
}

export const designActionsDiv = (formType = null , role = null) => {
	return (
		<div className="actions-col-div">
			{ (formType === 'form' ?  <Link tilte="Edit" to={void(0)} className="btn btn-sm action-btn edit-btn" title="Edit"><i className="fa-fw fi fi-rr-pencil"></i></Link> :  <button type="button" title="Edit" className="btn btn-sm action-btn edit-btn btn-blur" ><i className="fi fi-rr-pencil"></i></button> ) }
			{( role !== Constants.ROLE_ADMIN ? <Button type="button" title="Delete" variant="" size="sm" className="action-btn delete-btn btn-blur" ><i className="fi fi-rr-trash fa-fw"></i></Button> : '' )}
		</div>
	)
}

export const statusDiv = (e , updateRecordStatus , role = null) => {
 	var currentStatus = e.status;
 	var recordId = e.id
 	var setDisable = (role === Constants.ROLE_ADMIN ? 'disabled' : '');
 	return (
		<Form.Check type="switch" className="twt-custom-switch justify-content-start" disabled={setDisable ? true : false} id={"customSwitches-"+recordId+""} checked={currentStatus === Constants.ENABLE_STATUS_TEXT ? true : false} current-status={currentStatus === Constants.ENABLE_STATUS_TEXT ? Constants.ENABLE_STATUS_TEXT : Constants.DISABLE_STATUS_TEXT} data-record-id={recordId} onChange={(e) => updateRecordStatus(e)} label={currentStatus === Constants.ENABLE_STATUS_TEXT ? 'Enable' : 'Disable' }/>
     )
}

const handleNoResponse = () =>{
	localStorage.removeItem(Constants.LOGIN_COOKIE_NAME);
	window.dispatchEvent( new Event("storage"));
}

export function decode( string ){

	if(string === "" || string === null || string === "undefined" || string === undefined  ){
		return "";
	}
	
	var Utf8 = CryptoJS.enc.Utf8;
	const secretKey = Constants.ENCRYPTION_KEY
	const secretIv = Constants.ENCRYPTION_KEY + "_IV"
	const key= CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).substring(0,32);
	let iv= CryptoJS.SHA256(secretIv).toString(CryptoJS.enc.Hex).substring(0,16);
	const encrypt = CryptoJS.enc.Base64.parse(string).toString(CryptoJS.enc.Utf8);
	const decrypt = CryptoJS.AES.decrypt(encrypt, Utf8.parse(key), { iv: Utf8.parse(iv)}).toString(Utf8);
	return decrypt;

}

export const updateSequence = async (filterData , recordDetails , initialSequence , currentPage , perPage , setRenderRequired) => {
	if(recordDetails?.length > 0) {
		const sequenceArray = [];
		recordDetails.map((data) => (
			sequenceArray.push(decode(data?.id))
		))
		if(sequenceArray.toString() === initialSequence.toString()) {
			alertifyMessage("error", "No Sequence Changed.");
		} else {
			filterData.new_sequence = sequenceArray.length > 0 ? sequenceArray.join(',') : '';
			filterData.last_page = currentPage;
			filterData.length = perPage;
			const response = await apiCall('post' , '/lookup/sequence-update' , filterData)
			if(response?.status !== false) {
				if(response?.data?.status_code === 1) {
					setRenderRequired(true);
					alertifyMessage('success' , response?.data?.message);
				} else {
					alertifyMessage('error' , response?.data?.message);
				}
			} else {
				manageNetworkError(response);
			}
		}
	}
}

export const downloadBackup = async () => {
	const response = await apiCall('post' , '/dashboard/backup');
	if(response?.status !== false) {
		if(response?.data?.status_code === 1) {
			downloadSqlBackup(response?.data?.data)
		} else {
			alertifyMessage("error", response?.data?.message);
		}
	} else {
		manageNetworkError(response);
	}
}

export const downloadSqlBackup = (attachment) => {
    const linkSource = attachment?.data;
    const downloadLink = document.createElement('a');
    const fileName = `${attachment?.file_name}.sql`;
    
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const statusOptions = [
	{ value : "" , label : 'Select'},
	{ value : Constants.ENABLE_STATUS_TEXT , label : 'Enable'},
	{ value : Constants.DISABLE_STATUS_TEXT , label : 'Disable'}
]

export const handleException = (exception) => {
	localStorage.setItem( Constants.ERROR_COOKIE_NAME , JSON.stringify(exception?.data));
	window.dispatchEvent( new Event("storage"));
}

export const handlePageNotFound = () => {
	alertifyMessage("error" , 'Page Not Found');
}

export const focusOut = () => {
	document.querySelectorAll(".btn-blur").forEach(e => {
		e.blur();
	})
}

export const alertifyConfirm = ( confirmTitle, confirmMessage, onOkAction = function() {} , onCancelAction = function() {}, ) => {
	alertify.confirm().set({
		onshow:function(){
			//disable delete button
			document.querySelectorAll(".delete-btn").forEach(e => {
				e.disabled = true;
			})
			
			//Remove tabindex of backdrop modal
			document.querySelectorAll('.modal').forEach(e => {
				e.removeAttribute('tabindex');
			});
			
			// Focus on the OK button when the dialog is shown
			const okButton = document.querySelector('.btn-ok');
			if (okButton)
			okButton.focus();
		},
		onclose:function(){
			// Reassign tabindex of backdrop modal 
			document.querySelectorAll('.modal').forEach(e => {
				e.setAttribute('tabindex' , '-1');
			}); 
			
			//enable delete button
			document.querySelectorAll(".delete-btn").forEach(e => {
				e.disabled = false;
			})
		}
	});
	alertify.confirm(confirmTitle , confirmMessage , onOkAction , onCancelAction );
}

export const statusUpdate = async (thisItem, moduleUrl, setRenderRequired , statusInfo = {}) => {
	
	statusInfo.record_id = thisItem.getAttribute('data-record-id');
	statusInfo.current_status = thisItem.getAttribute('current-status');

	var updateStatus = await apiCall( 'post' , moduleUrl + '/updateStatus' , statusInfo );
	if( updateStatus?.status !== false ){
		if( updateStatus?.data?.status_code === 1) {
				alertifyMessage("success" , updateStatus?.data?.message );
				setRenderRequired(true);
		   } else {
			   alertifyMessage("error" , updateStatus?.data?.message );
		   }	
	} else {
		manageNetworkError(updateStatus);
	}
}

//dynamic meta tags 
export const dynamicMetaTags = [
    'meta[name="title"]',
    'meta[name="description"]',
    'meta[name="keywords"]',
    'meta[name="author"]',
    'meta[name="copyright"]',
    'meta[name="publisher"]',

    'meta[property="og:title"]',
    'meta[property="og:description"]',
    'meta[property="og:url"]',
    'meta[property="og:site_name"]',
    'meta[property="og:image"]',

    'meta[property="twitter:url"]',
    'meta[property="twitter:title"]',
    'meta[property="twitter:description"]',
    'meta[property="twitter:image"]',

    'link[rel="icon"]',
    'link[rel="apple-touch-icon"]',
    
    'meta[name="theme-color"]'
];

export const noSpace = (value , fieldName) => {
	if(value && !value.toString().trim()) {
		return "Please Enter " + fieldName;
	}
}