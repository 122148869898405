import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Constants from '../../config/constants';
import { useNavigate, Link } from "react-router-dom";
import { seo } from "../../seo";
import { emailRegex, apiCall, manageNetworkError, noSpace, dynamicMetaTags } from "../../assets/js/common";
import { useTranslation } from "react-i18next";
import {
	Container,
	Row,
	Col,
	Form,
} from "react-bootstrap";
import InputTextField from "../../component/InputTextField";
import FlashMessage from "../../component/FlashMessage";
import { HelmetProvider } from 'react-helmet-async';
import LoginHeader from "./guest/login-header";

export default function ForgotPassword() {

    const { t } = useTranslation( [ "translation", "errorMessage" ]);
    const [settingInfo, setSettingInfo] = useState([]);
    const [flashMessage , setFlashMessage] = useState(null);
    const navigate = useNavigate();
    const [isloggedIn , setIsLoggedIn] = useState(false);
    const loginUserInfo = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        if(loginUserInfo && loginUserInfo.token) {
            setIsLoggedIn(true);
        }
    } , [loginUserInfo]);

    useEffect(() => {
        if(isloggedIn !== false) {
            navigate(Constants.SUCCESS_REDIRECT_PAGE_URL);
        }
    } , [navigate, isloggedIn]);


    useEffect(() => {
        getSettingInfo();
    } , []);

    useEffect(() => {
        seo({
          title: t('forgot-password') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [t, settingInfo]);

    const formDefaultValue = {
        login_email : ''
    }
    
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues : formDefaultValue,
        reValidateMode:'onChange'
    });

    const onsubmit = async(data) => {
        setButtonDisabled(true);

        const response = await apiCall('post' , Constants.SEND_FORGOT_PASSWORD_MAIL_URL , data);

        if(response?.status !== false) {
            if(response?.data?.status_code === 1) {
                setFlashMessage({type: 'success' , message: response?.data?.message , doNotConvert: true});
            } else {
                setFlashMessage({type: 'danger' , message: response?.data?.message , doNotConvert: true});
            }
        } else {
            manageNetworkError(response);
        }
        reset(formDefaultValue);
        setButtonDisabled(false);
    }

    useEffect(() => {
        const removeTagList = dynamicMetaTags.join(',');
        document.querySelectorAll(removeTagList).forEach(e => {
          if(!e.getAttribute('data-react-helmet')) {
            e.remove();
          }
        });
    } , []);

    return (
        <HelmetProvider>
            <LoginHeader pageName={t('forgot-password')} settingInfo={settingInfo} />

            <section className="login-page-section">
                <Container>
                    <div className="login-page-items">
                        <div className="login-items">
                            <Row>
                                <Col lg={5}>
                                    <div className="overlay-items">
                                        <div className="overlay flex-column">
                                            <h2 className="login-title pb-3 text-uppercase">{t('forgot-password')}</h2>
                                            <p className="w-75 text-center text-capitalize">We will send you reset password link.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className="form-class">  
                                        <div className="password-icon">
                                            <i className="fa fa-lock"></i>
                                        </div>
                                        <div className="form-items">
                                            <Form onSubmit={handleSubmit(onsubmit)} id="forgot-password-form">
                                                    <Row>
                                                        {flashMessage && (
                                                            <FlashMessage type={flashMessage?.type} message={flashMessage?.message} setFlashMessage={setFlashMessage} convert={flashMessage?.doNotConvert ? false : true} />
                                                        )}
                                                        <InputTextField control={control} errors={errors} rules={{ required: t('required-enter-field-validation' , { module:t('email-id')}), validate: (value => noSpace(value, t('email-id')) || emailRegex(value, t('invalid-enter-field-validation' , {module:t('email-id')}))) }} column={12} label={t("email-id")} placeholder={t("email-id")}  name="login_email" autoFocus required />
                                                        <Col lg={12}>
                                                            <Form.Group>
                                                                <button type="submit" className="btn submit-class" disabled={buttonDisabled}     title={t('forgot-password')}>{t('forgot-password')}</button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                            </Form>
                                        </div>
                                        <Link to={Constants.LOGIN_URL} className="back-to-login" title={t('back-to-login')}><i className="fa fa-arrow-left me-2"></i> {t('back-to-login')}</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
        </section>
      </HelmetProvider>
    )
}