import React from "react";
import {
  Container,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ActionTopBtns from "../Utilities/ActionTopBtns";

const ListingPageLayout = ({ pageTitle, children, totalRecords, actionBtnData, filterOpen, setFilterOpen, filter, filterBtnClassName, filterBtnTitle, filterBtnIcon, className }) => {
  const { t } = useTranslation();
  return (
    <main className={`page-height bg-light-color ${className !== undefined ? className : '' }`}>	
    	<div className="breadcrumb-wrapper d-flex border-bottom">
        <h1 className="h3 mb-0 me-3 header-title" id="pageTitle">{pageTitle} {totalRecords !== undefined ? <span className="total-record-count">({totalRecords})</span> : ''}</h1>
        <ActionTopBtns 
          filterBtn={filter} 
          actionTopBtns={actionBtnData} 
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen} 
          filterBtnClassName={filterBtnClassName !== undefined ? filterBtnClassName : ''}
          filterBtnTitle={filterBtnTitle !== undefined && filterBtnTitle !== '' ? filterBtnTitle : t('filter')} 
          filterBtnIcon={filterBtnIcon !== undefined && filterBtnIcon !== '' ? filterBtnIcon : 'fi fi-rr-settings-sliders'} 
        />
		  </div>
      <Container fluid className="filter-result-container">
        {children}
      </Container>
    </main>
  );
};
export default ListingPageLayout;