import React, { useState, useRef, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';

const DropdownField = ( props = {} ) => {
	const ref = useRef();
	const [currentWidth, setCurrentWidth] = useState();

	const updateWidth = () => {
        const element = ref.current.getElement();
        setCurrentWidth(element.clientWidth);
    };

	useEffect(() => {
        updateWidth();
    }, []);

	const fieldName = props?.name;
	const formErrors = props?.errors;

	const defaultOption = [
		{ label: 'Select', value: '' },
  	];

	return (
        <Col lg={props.column}>
        	<Form.Group className="form-group">
        		<Form.Label>{props?.label}{ props?.required ? <span className="text-danger">*</span> : '' }</Form.Label>
        		<Controller
	        	    name={props?.name}
	        	    control={props?.control}
					defaultValue={props?.value}
		            rules={ props?.rules ?  props?.rules : "" }
	        	    render={({ field, fieldState }) => (
						<Dropdown
							ref={ref}
							name={props?.name}
							value={field.value}
							placeholder={props?.placeholder}
							options={props?.options ? props.options : defaultOption}
							disabled={props?.disabled}
							focusInputRef={field.ref}
							onChange={(e) => {
								field.onChange(e.value);
								if(props?.onFieldChange) {
									props?.onFieldChange(e);
								}
							}}
							className={classNames({ 'p-invalid': fieldState.error }, ( props?.classList ? props?.classList : '' ))}
							pt={{
								panel: {
									style: {
									...(currentWidth ? { width: currentWidth } : {}),
									},
								},
							}}
							onShow={updateWidth}
							onFocus={updateWidth}
							filter={props?.isFilterEnabled}
							autoOptionFocus={props?.isFilterEnabled}
							resetFilterOnHide={props?.isFilterEnabled}
						/>
	        	    )}
	        	/>
				{ ( formErrors && formErrors[fieldName]?.message ) && ( 
					<Form.Label className="invalid-input" htmlFor={props?.name}>{formErrors[fieldName]?.message}</Form.Label> 
        	    )}
        	</Form.Group>
        </Col>
    )
}
export default DropdownField