import {React, lazy, Suspense} from 'react';
import { Route } from "react-router-dom";
import * as Constants from "../config/constants";
import DesignUsers from '../pages/design/admin/users';
const DesignAddUser = lazy(() => import('../pages/design/admin/add-user'));

const DesignPatientLogin = lazy(() => import('../pages/design/admin/patient-login'));
const DesignForgotPassword = lazy(() => import('../pages/design/admin/forgot-password'));
const DesignPatientRegistration = lazy(() => import('../pages/design/admin/patient-registration'));
const DesignReferringDoctors = lazy(() => import('../pages/design/admin/referring-doctors'));

const DesignRoutes =[
  <Route key="des-1" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-users'} element={<DesignUsers pageName="Users" />} />,
	<Route key="des-2" path={Constants.ADMIN_PANEL_URL_PREFIX + '/users/design-create'} element={<Suspense><DesignAddUser pageName="Add User" /></Suspense>} />,

  <Route key="des-3" path={'/design-patient-login'} element={<Suspense><DesignPatientLogin pageName="Patient Login" /></Suspense>} />,
  <Route key="des-3" path={'/design-forgot-password'} element={<Suspense><DesignForgotPassword pageName="Forgot Password" /></Suspense>} />,
  <Route key="des-4" path={'/design-patient-registration'} element={<Suspense><DesignPatientRegistration pageName="Patient Registration" /></Suspense>} />,
  <Route key="des-5" path={Constants.ADMIN_PANEL_URL_PREFIX + '/design-referring-doctors'} element={<Suspense><DesignReferringDoctors pageName="Referring Doctors" /></Suspense>} />
];

export default DesignRoutes;