import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { focusOut } from '../../assets/js/common';

const ActionTopBtns = ({ actionTopBtns, filterOpen, setFilterOpen, filterBtn, filterBtnClassName, filterBtnTitle, filterBtnIcon }) => {
  return (
    <div className="ms-auto pt-sm-0 d-flex align-items-center">
      {actionTopBtns && actionTopBtns.map((btn, index) => (
        btn.show === true ? 
          btn.button === true ?
          <Button 
            key={index}
            type="button"
            size="sm" 
            onClick={() => {
              btn.onClick();
              focusOut();
            }} 
            variant={btn.variant !== undefined && btn.variant !== '' ? btn.variant : 'theme'}
            className={`d-flex align-items-center text-white border me-10 button-actions-top-bar twt-add-btn add-btn ${btn.className !== undefined ? btn.className : ''}`} 
            title={btn.title}>
            {btn.icon && <i className={`twt-add-icon ${btn.icon !== undefined && btn.icon !== '' && btn.icon !== true ? btn.icon : 'fi fi-rr-plus'} `}></i>}
            {btn.title && <span className="d-sm-block d-none">{btn.title}</span>}
          </Button> 
          :
          <Link
            key={index}
            to={btn.url}
            className={`d-flex align-items-center btn btn-theme text-white border btn-sm me-10 button-actions-top-bar twt-add-btn add-btn ${btn.className !== undefined ? btn.className : ''}`}
            title={btn.title}>
            {btn.icon && <i className={`twt-add-icon ${btn.icon !== undefined && btn.icon !== '' && btn.icon !== true ? btn.icon : 'fi fi-rr-plus'} `}></i>}
            {btn.title && <span className="d-sm-block d-none">{btn.title}</span>}
        </Link>
      :
      ''
      ))}
      {filterBtn === true && (
        <Button
          variant=""
          size="sm"
          className={`d-flex align-items-center text-white border button-actions-top-bar twt-filter-btn add-btn ${filterBtnClassName}`}
          onClick={() => setFilterOpen(!filterOpen)}
          aria-controls="filter-collapse"
          aria-expanded={filterOpen}
          title={filterBtnTitle}
        >
          <i className={`twt-filter-icon ${filterBtnIcon}`}></i>
          <span className="d-none d-sm-block">{filterBtnTitle}</span>
        </Button>
      )}
    </div>
  );
};

export default ActionTopBtns;