import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Constants from '../../config/constants';
import { Link, useNavigate, useParams } from "react-router-dom";
import { seo } from "../../seo";
import { apiCall, manageNetworkError, noSpace, dynamicMetaTags } from "../../assets/js/common";
import { useTranslation } from "react-i18next";
import {
	Container,
	Row,
	Col,
	Form,
} from "react-bootstrap";
import PasswordField from "../../component/PasswordField";
import FlashMessage from "../../component/FlashMessage";
import { HelmetProvider } from 'react-helmet-async';
import LoginHeader from "./guest/login-header";

export default function ResetPassword() {

    const { t } = useTranslation( [ "translation", "errorMessage" ]);
    const navigate = useNavigate();
    const params = useParams();
    const encodedData = params?.encodedData;
    const [userId , setUserId] = useState(null);
    const [settingInfo, setSettingInfo] = useState([]);
    const [flashMessage , setFlashMessage] = useState(null);

    const getSettingInfo = async () => {
    
        const getSettingData = await apiCall( 'get' , Constants.GET_SETTING_INFO_URL );
        if(getSettingData.status !== false) {
            if(getSettingData?.data?.data) {
                setSettingInfo(getSettingData.data.data?.recordInfo);
            }
        } else {
            manageNetworkError(getSettingData);
        }
    }

    useEffect(() => {
        getSettingInfo();
    } , []);

    useEffect(() => {
        seo({
          title: t('reset-password') + ' | ' + (settingInfo?.site_title ? settingInfo.site_title : ''),
        });
    }, [t, settingInfo]);

    const formDefaultValue = {
        new_password: '',
        confirm_password: ''
    }
    
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setError,
        reset,
    } = useForm({
        defaultValues: formDefaultValue,
        reValidateMode:'onChange'
    });

    const validateEncodedData = async () => {
        const response = await apiCall('get' , Constants.LOGIN_URL + '/newPassword/' + encodedData);

        if (response?.status !== false) {
            if(response?.data?.status_code === 1) {
                setUserId(response?.data?.data?.user_id)
            } else {
                navigate(Constants.LOGIN_URL , {state: {type: 'danger' , message: response?.data?.message}});
            }
        } else {
            manageNetworkError(response);
        }
    }

    useEffect(() => {
        validateEncodedData();
        // eslint-disable-next-line
    } , []);

    const onsubmit = async(data) => {
        data.user_id = userId;

        var checkIsStrong = await checkStrongPassword(data);
       
        if(checkIsStrong !== false){
        	const response = await apiCall('post' , Constants.LOGIN_URL + Constants.UPDATE_PASSWORD_URL , data);

            if (response?.status !== false) {
                if(response?.data?.status_code === 1) {
                    navigate(Constants.LOGIN_URL , {state: {type: 'success' , message: response?.data?.message}});
                } else {
                    setFlashMessage({type: 'danger' , message: response?.data?.message});
                    reset(formDefaultValue);
                }
            } else {
                manageNetworkError(response);
            }
        }
    }

    const checkStrongPassword = async(data) => {
    	
    	var checkData = {};
    	checkData.is_api_request = Constants.SELECTION_YES;
    	checkData.new_password = data?.new_password.trim();
    	checkData.user_id = data?.user_id;
    	var response = await apiCall('post', Constants.CHECK_STRONG_PASSWORD_URL , checkData);
    	
    	if(response?.status !== false){
    		if(response?.data?.status_code === 1){
    			return true;
    		} else {
    			setError('new_password' , {message: response?.data?.message});
    		}
    	} else {
    		manageNetworkError(response);
    	}
    	return false;
    }

    useEffect(() => {
        const removeTagList = dynamicMetaTags.join(',');
        document.querySelectorAll(removeTagList).forEach(e => {
          if(!e.getAttribute('data-react-helmet')) {
            e.remove();
          }
        });
    } , []);

    return (
        <HelmetProvider>
            <LoginHeader pageName={t('reset-password')} settingInfo={settingInfo} />
            <section className="login-page-section">
                <Container>
                    <div className="login-page-items">
                        <div className="login-items">
                            <Row>
                                <Col lg={5}>
                                    <div className="overlay-items">
                                        <div className="overlay">
                                            <h2 className="login-title">{t('reset-password')}</h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className="form-class">  
                                        <div className="password-icon">
                                            <i className="fa fa-lock"></i>
                                        </div>
                                        <div className="form-items">
                                            <Form onSubmit={handleSubmit(onsubmit)} id="reset-password-form">
                                                    <Row>
                                                        {flashMessage && (
                                                            <FlashMessage type={flashMessage?.type} message={flashMessage?.message} setFlashMessage={setFlashMessage} convert={flashMessage?.doNotConvert ? false : true} />
                                                        )}
                                                        <PasswordField control={control} errors={errors} rules={{ required: (t('required-enter-field-validation' , { module:t('new-password')})), validate: (value => noSpace(value, t('new-password')))}} label={t('new-password')} column={12} placeholder={t('new-password')} name="new_password" autoFocus required />
                                                        
                                                        <PasswordField control={control} errors={errors} rules={{ required: (t('required-enter-field-validation' , { module:t('confirm-password')})) , validate: (value) => noSpace(value, t('confirm-password')) || value === watch("new_password") || t('new-password-confirm-password-not-match')}} label={t('confirm-password')} column={12} placeholder={t('confirm-password')} name="confirm_password" autoComplete="off" required />

                                                        <Col lg={12}>
                                                            <Form.Group>
                                                                <button type="submit" className="btn submit-class" title={t('reset-password')}>{t('reset-password')}</button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                            </Form>
                                        </div>
                                        <Link to={Constants.LOGIN_URL} className="back-to-login" title={t('back-to-login')}><i className="fa fa-arrow-left me-2"></i> {t('back-to-login')}</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
        </HelmetProvider>
    )
}