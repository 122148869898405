import * as twtConfig from "./twt_config";  

export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;
export const ERROR_COOKIE_NAME = twtConfig.ERROR_COOKIE_NAME;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;
export const SITE_TITLE = twtConfig.SITE_TITLE;
export const ADMIN_PANEL_URL_PREFIX = twtConfig.ADMIN_PANEL_URL_PREFIX;
export const DEFAULT_TWT_ADMIN_EMAIL = twtConfig.DEFAULT_TWT_ADMIN_EMAIL;
export const SHOW_FORGOT_PASSWORD_LINK = twtConfig.SHOW_FORGOT_PASSWORD_LINK;
export const SHOW_REMEMBER_ME_CHECKBOX = twtConfig.SHOW_REMEMBER_ME_CHECKBOX;
export const REMEMBER_ME_TIME = twtConfig.REMEMBER_ME_TIME;

//Pagination
export const PER_PAGE = twtConfig.PER_PAGE;
export const SELECT_ALL_ROWS_TEXT = twtConfig.SELECT_ALL_ROWS_TEXT;
export const PER_PAGE_OPTIONS = twtConfig.PER_PAGE_OPTIONS;

//Show Confirm Box
export const SHOW_CONFIRM_BOX = twtConfig.SHOW_CONFIRM_BOX;

// Encryption Key 
export const ENCRYPTION_KEY = twtConfig.ENCRYPTION_KEY;

//Show Developer Settings
export const SHOW_DEVELOPER_SETTINGS = twtConfig.SHOW_DEVELOPER_SETTINGS;

// only Admin Panel 
export const ONLY_ADMIN_PANEL = twtConfig.ONLY_ADMIN_PANEL;

//send OTP
export const SEND_LOGIN_OTP = twtConfig.SEND_LOGIN_OTP;
export const OTP_LENGTH = twtConfig.OTP_LENGTH;
export const LOGIN_RESEND_OTP_TIME = twtConfig.LOGIN_RESEND_OTP_TIME;

export const DISPLAY_DATE_FORMAT = 'd-m-Y';
export const DISPLAY_DATE_TIME_FORMAT = 'd-m-Y h:i:s A';
export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

export const ACTIVE_STATUS = 1;
export const INACTIVE_STATUS = 0;
export const ACTIVE_STATUS_TEXT = "Active";
export const INACTIVE_STATUS_TEXT = "Inactive";
export const ENABLE_STATUS_TEXT = "Enable";
export const DISABLE_STATUS_TEXT = "Disable";
export const SELECTION_YES = 'Yes';
export const SELECTION_NO = 'No';
export const LEFT = 'left';
export const RIGHT = 'right';
export const ROLE_ADMIN = 'admin';
export const ADMIN_THEME_COLOR = "#fff";

export const LANDING_PAGE_URL = "/";
export const LOGIN_URL = '/login';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const RESET_PASSWORD_URL = '/newPassword';
export const VERIFY_OTP_URL = '/verifyOtp';
export const LOGOUT_URL = '/logout';
export const DASHBOARD_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/dashboard';
export const SUCCESS_REDIRECT_PAGE_URL = DASHBOARD_PAGE_URL;
export const LOGIN_HISTORY_URL = ADMIN_PANEL_URL_PREFIX + '/login-history';
export const SETTING_URL = ADMIN_PANEL_URL_PREFIX + '/settings';
export const GET_SETTING_INFO_URL = '/getSettingInfo';
export const CHANGE_PASSWORD_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/change-password';
export const USER_URL = ADMIN_PANEL_URL_PREFIX + '/users';
export const LOOKUP_URL = ADMIN_PANEL_URL_PREFIX + '/lookup';
export const UPDATE_PASSWORD_URL = '/updatePassword';
export const CHECK_STRONG_PASSWORD_URL = '/checkStrongPassword';
export const CHECK_UNIQUE_USER_URL = '/checkUniqueUserEmail';
export const GET_CUSTOM_DROPDOWN_URL = '/getCustomDropdownDetails';
export const ADD_LOOKUP_URL = '/add-lookup-master';
export const SEND_FORGOT_PASSWORD_MAIL_URL = LOGIN_URL + '/sendForgotPasswordMail';
export const CHECK_LOGIN_URL = LOGIN_URL + '/checkLogin';
export const CHECK_OTP_URL = LOGIN_URL + '/checkOtp';
export const RESEND_OTP_URL = LOGIN_URL + '/resendLoginOtp';
export const EXCEPTION_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/exception';

export const DATATABLE_HEIGHT = 'calc(100vh - 260px)';

//<---Need to remove this constants, while setup new project--->
export const LOCATION_URL = ADMIN_PANEL_URL_PREFIX + '/locations';
export const LOCATION_MASTER_LOOKUP = 'location';
export const TYPE_URL = ADMIN_PANEL_URL_PREFIX + '/types';
export const TYPE_MASTER_LOOKUP = 'type';
//<---Need to remove this constants, while setup new project--->
