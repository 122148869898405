import React from "react";
import { Col, Form } from "react-bootstrap";
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";

const InputTextField = ( props = {} ) => {

	const fieldName = props?.name;
	const formErrors = props?.errors;

	return (
        <Col lg={props.column} className={`${props?.blankLable === true && props?.blankLable !== undefined ? 'blank-label' : ''}`}>
        	<Form.Group className="form-group">
				<Form.Label className="control-label">{props?.label}{ props?.required ? <span className="text-danger">*</span> : '' }</Form.Label>
        		<Controller
		            name={props?.name}
        			control={props?.control}
		            rules={ props?.rules ?  props?.rules : "" }
		            render={({ field, fieldState }) => (
		            	<>	
		                    <InputText
								{...field} 
								name={props?.name}
		                    	placeholder={props?.placeholder} 
		                    	value={field?.value || ''}
		                    	className={ classNames( { 'p-invalid': fieldState.error } , ( props?.classList ? props?.classList : '' ) ) } 
		                    	onChange={(e) => {
									if(props?.onFieldChange) {
										props.onFieldChange(e);
									}
									field.onChange(e.target.value);
								}}
								onKeyPress={props?.onKeyPress}
								autoComplete={props?.autoComplete || "off"}
								autoFocus={props?.autoFocus}
								maxLength={props?.maxLength}
								onKeyUp={props?.onKeyUp}
								disabled={props?.disabled}
								onBlur={props?.onFieldBlur}
								onFocus={props?.onFocus}
		                    />
	                    </>
		            )}
        		/>
        		{ ( formErrors && formErrors[fieldName]?.message ) && ( 
					<Form.Label className="invalid-input" htmlFor={props?.name}>{formErrors[fieldName]?.message}</Form.Label> 
        	    )}
        	</Form.Group>
        </Col>
    )
}
export default InputTextField