import React, {useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import * as Constants from "../../../config/constants";
import { useTranslation } from "react-i18next";
import {handleActiveClass} from '../../../assets/js/common.js';
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/images/icon.png";
import demoUser from "../../../assets/images/demo-user.png";
import {
    Navbar,
    Dropdown
} from "react-bootstrap";

export default function Header({settingInfo}) {

    useEffect(() => {
        handleActiveClass();
    }, []);
    if (window.innerWidth > 1199) {
        const wrapper = document.querySelector('#wrapper');
        const navSlideOpen = document.querySelector('#backend-content');
        if (wrapper && wrapper.classList) {
            wrapper.classList.add("toggled");
            navSlideOpen.classList.add("nav-slide-open");
            navSlideOpen.classList.add("toggled");
            
        }
    }

    const toggleNav = () => {
        document.querySelector('#wrapper').classList.toggle("toggled");
        document.querySelector('.backend-content').classList.toggle("toggled");
    };
    document.addEventListener("click", function(e) {
        if (window.innerWidth < 1199 && !e.target.closest("#wrapper")) {
            document.querySelector('#wrapper').classList.remove("toggled");
        }
    });

    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    return (
        <header>
            <main className="twt-theme theme-colour vertical-header">
                <div id="wrapper" className="wrapper">
                    <Navbar className="navbar-dark" expand="">
                        <button onClick={toggleNav} className="navbar-toggler ripple me-auto d-xl-none d-flex" type="button">
                            <span className="navbar-toggler-icon" />
                        </button>
                        {/* {user && user?.role && user.role === Constants.ROLE_ADMIN && (
                            <li className="nav-item ms-auto d-block me-3"><NavLink className="nav-link" to={Constants.DASHBOARD_PAGE_URL + '/backup'} title={t('download-backup')} onClick={(e) => {e.preventDefault(); downloadBackup()}}><i className="fas fa-download fa-fw"></i> <span className="d-none d-sm-inline-block">{t('download-backup')}</span><span className="d-sm-none d-inline-block">{t('backup')}</span></NavLink></li>
                        )} */}
                        <Dropdown className="dropdown admin-dropdown me-lg-0 ms-auto">
                            <Dropdown.Toggle variant="" className="admin-dropdown" id="admin-dropdown">
                                <span className="img-user align-middle rounded-circle d-blcok">
                                    <div className="user-img-div user-srot-name-div">
                                        <p className="user-srot-name">R</p>
                                    </div>
                                    <div className="user-img-div">
                                        <img src={demoUser} alt="Demo User" />
                                    </div>
                                    <div className="user-content-div">
                                        <p id="username" className="d-inline-block align-middle user-name">{(user && user.name) ? user.name : ''}</p>
                                        <p id="username" className="d-inline-block align-middle user-designation">Lorem Lipsum</p>
                                    </div>
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-right">
                                <Link className="dropdown-item" to={Constants.CHANGE_PASSWORD_PAGE_URL} title={t('change-password')}><i className="fas fa-lock password"></i> {t('change-password')}</Link>
                                <hr className="admin-dropdown-hr" />
                                <Link to={Constants.LOGOUT_URL} className="dropdown-item logout-btn text-dark text-decoration-none font-15 d-flex align-items-center" title={t('logout')}><i className="fas fa-sign-out-alt password"></i> {t('logout')}</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <div className="logout logout-btn-items d-sm-flex d-none ">
                            <Link to={Constants.LOGOUT_URL} title={t('logout')} className="d-sm-flex d-none logout-btn text-dark text-decoration-none font-15  align-items-center">
                                <i className="fas fa-power-off me-2" /><span>{t('logout')}</span>
                            </Link>
                        </div> */}
                    </Navbar>
                    <div className="sidebar" id="sidebar">
                        <ul className="sidebar-nav">
                            <li className="text-center nav-users nav-logo-li">
                                <Link className="navbar-brand p-lg-0" to={Constants.DASHBOARD_PAGE_URL}>
                                    {settingInfo?.v_website_logo && settingInfo.v_website_logo ? (
                                        <img src={settingInfo?.v_website_logo ? Constants.BACKEND_FILE_URL + settingInfo.v_website_logo : ''} alt={t('praheal')} className="img-fluid big-image nav-logo" />
                                    ) : (
                                        <img src={logo} alt={t('praheal')} className="img-fluid big-image nav-logo"/>
                                    )}
                                    {settingInfo?.v_website_fav_icon && settingInfo.v_website_fav_icon ? (
                                        <img src={settingInfo?.v_website_fav_icon ? Constants.BACKEND_FILE_URL + settingInfo.v_website_fav_icon : ''} alt={t('praheal')} className="img-fluid big-image nav-logo nav-logo-toggla"/>
                                    ) : (
                                        <img src={icon} alt={t('praheal')} className="img-fluid big-image nav-logo nav-logo-toggla"/>
                                    )}
                                </Link>
                            </li>
                            <li className="nav-items-class items-link">
                                <Link to={Constants.DASHBOARD_PAGE_URL} className="nav-link d-flex align-items-center first-menu" title={t('dashboard')}>
                                    <i className="fi fi-rr-dashboard fa-fw" />
                                    <span className="nav-text">{t('dashboard')}</span>
                                </Link>
                            </li>
                            <li className="nav-items-class nav-items dropdown">
	                            <a href="#manageMenu" data-bs-toggle="collapse" className="main-drodown-toggle dropdown-toggle nav-link first-menu collapsed" title={t('design')}>
	                                <i className="fi fi-rr-apps fa-fw" aria-hidden="true" />
	                                <span className="nav-text">{t('design')}</span>
	                            </a>
	                            <ul className="collapse navbar-collapse sub-dropdown-collapse sub-dropdown-menu" id="manageMenu">
	                                <li className="nav-items">
	                                    <Link to={Constants.ADMIN_PANEL_URL_PREFIX + '/design-users'} className="nav-link nav-link-text second-menu d-flex align-items-center nav-link-items" title={t('users')}>
                                            <i className="fi fi-br-minus-small me-2" />
                                            <span className="nav-text">{t('users')}</span>
                                        </Link>
	                                </li>
	                            </ul>
	                        </li>
                            <li className="nav-items nav-items-class dropdown">
                                <Link className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('menu')} to="#manageMenu1" data-bs-toggle="collapse"><i className="fi fi-rr-boxes fa-fw" aria-hidden="true"></i><span className="nav-text"> {t('menu')}</span></Link>
                                <ul className="collapse navbar-collapse items-drodown-menu" id="manageMenu1">
                                    <li className="dropdown sub-dropdown-menu dropdown-sub-megamenu nav-items">
                                        <Link className="nav-link dropdown-toggle  main-drodown-toggle second-menu collapsed" title={t('menu')} to="#collapseOne" data-bs-toggle="collapse"> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('menu')}</span></Link>
                                        <ul className="collapse navbar-collapse sub-dropdown-collapse items-drodown-menu" id="collapseOne">
                                            <li className="dropdown-item dropdown nav-items dropdown-sub-megamenu third-dropdown-menu">
                                                <Link className="nav-link dropdown-item dropdown-toggle  main-drodown-toggle third-menu collapsed" title={t('menu1')} to="#manageMenu11" data-bs-toggle="collapse"><span className="nav-text"><i className="dot-icon fa-solid fa-circle me-2"></i>{t('menu1')}</span></Link>
                                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu11">
                                                    <li className="nav-items"><Link className="nav-link nav-link-text fourth-menu" title={t('menu1')} to="product1"> <span className="nav-text"> <i className="fa fa-dot-circle me-2"></i> {t('menu1')} </span> </Link></li>
                                                    <li className="nav-items"><Link className="nav-link nav-link-text fourth-menu" title={t('menu2')} to="product2"> <span className="nav-text"><i className="fa fa-dot-circle me-2"></i> {t('menu2')}</span> </Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            { user?.role && user?.role === Constants.ROLE_ADMIN && (
                            <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('masters')} href="#manageMenu2" data-bs-toggle="collapse"><i className="fi fi-rr-user-gear fa-fw" aria-hidden="true"></i><span className="nav-text">{t('masters')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu2">
                                    <li className="nav-items">
                                        <Link className="nav-link nav-link-text" title={t('users')} to={Constants.USER_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('users')}</span></Link>
                                    </li>
                                    <NavLink className="nav-items" to={Constants.LOCATION_URL}>
                                        <span className="nav-link nav-link-text" title={t('locations')}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('locations')}</span></span>
                                    </NavLink>
                                    <NavLink className="nav-items" to={Constants.TYPE_URL}>
                                    	<span className="nav-link nav-link-text" title={t('types')}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('types')}</span></span>
                                    </NavLink>
                                </ul>
                            </li>
                            )}
                            <li className="nav-items-class items-link">
                                <Link to={Constants.LOGIN_HISTORY_URL} className="nav-link d-flex align-items-center first-menu" title={t('login-history')}>
                                    <i className="fi fi-rr-time-past fa-fw" />
                                    <span className="nav-text">{t('login-history')}</span>
                                </Link>
                            </li>
                            <li className="nav-items-class items-link">
                                <Link to={Constants.SETTING_URL} className="nav-link d-flex align-items-center first-menu" title={t('settings')}>
                                    <i className="fi fi-rr-settings fa-fw" />
                                    <span className="nav-text">{t('settings')}</span>
                                </Link>
                            </li>
                            {!Constants.ONLY_ADMIN_PANEL && (
                                <>
                                <hr />
                                <li className="nav-items-class">
                                    <Link to={Constants.LANDING_PAGE_URL} className="nav-link d-flex align-items-center first-menu" title={t('visit-site')} target="_blank" rel="noopener noreferrer">
                                        <i className="fi fi-rr-site-alt fa-fw" />
                                        <span className="nav-text">{t('visit-site')}</span>
                                    </Link>
                                </li>
                                </>
                            )}
                        </ul>
                        {/* {settingInfo?.v_powered_by && (
                            <div className="fixed-footer border-top p-2">
                                <p className="text-center small mb-0">© {moment().year()} <a href={settingInfo?.v_powered_by_link ? settingInfo.v_powered_by_link : void(0) } target="_blank" rel="noopener noreferrer">{settingInfo.v_powered_by}</a></p>
                            </div>
                        )} */}
                    </div>
                </div>
            </main>
        </header>
    );
}