import React from 'react';
import { useTranslation } from "react-i18next";
import {
	Button,
	Col,
	Form,
} from "react-bootstrap";

const FilterActionBtns = ({ lg, resetOnClick, searchOnClick, searchClassName, resetClassName,resetBtnIcon,searchBtnIcon }) => {
  const { t } = useTranslation();
  return (
    <Col lg={lg !== undefined && lg !== '' ? lg : 3} className="twt-search-div">
      <Form.Group className="form-group">
        <Button type="button" variant="theme" onClick={searchOnClick} className={`text-white twt-search-btn me-10 ${searchClassName !== undefined ? searchClassName : ''}`} title={t('search')}> {searchBtnIcon === false ? '' : <i className={`twt-filter-icon ${searchBtnIcon !== undefined && searchBtnIcon !== '' && searchBtnIcon !== true ? searchBtnIcon : 'fi fi-rr-search'} `}></i> }{t('search')}</Button>
        <Button type="button" variant="outline-secondary" onClick={resetOnClick} className={`reset-wild-tigers twt-reset-btn ${resetClassName !== undefined ? resetClassName : ''}`} title={t('reset')}>{resetBtnIcon === false ? '' : <i className={`twt-filter-icon ${resetBtnIcon !== undefined && resetBtnIcon !== '' && resetBtnIcon !== true ? resetBtnIcon : 'fi fi-rr-rotate-right'} `}></i> }{t('reset')}</Button>
      </Form.Group>
    </Col>
  );
};

export default FilterActionBtns;